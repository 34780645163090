<template>
    <a
        v-if="booted"
        id="intercom-launcher"
        href="javascript:void(0)"
    >     
        <div
            class="icon-open"
            :class="{'open' : open }"
        >
            <img
                src="/img/intercom_icon_open.svg"
                alt="intercom open"
            >                   
        </div>
        <div
            class="icon-close"
            :class="{'open' : open }"
        >
            <img
                src="/img/intercom_icon_close.svg"
                alt="intercom close"
            >
        </div>
    </a>
</template>

<script>
export default {
    name: "Intercom",
    props: {
        isCollapsed: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            open: false,
            booted: false,
        };
    },

    computed: {
        isActiveFarm() {
            return window.globalVars.activeFarmId !== false;
        }
    },

    mounted() {
        window.addEventListener("Intercom_booted", () => {
            this.booted = true;
        });
    },

    created() {   
        window.Intercom("onShow", () => {
            this.open = true;
        }); 

        window.Intercom("onHide", () => {
            this.open = false;
        });

        let horizontalPadding = 75;
        let verticalPadding = 67;
        if (this.isActiveFarm) {
            horizontalPadding = this.isCollapsed ? 0 : 120;
            verticalPadding = this.isCollapsed ? 180 : 67;
        }

        window.intercomSettings.horizontal_padding  = horizontalPadding;
        window.intercomSettings.vertical_padding    = verticalPadding;
        window.Intercom("update");
    }
}
</script>

<style lang="scss">
#intercom-launcher {
    position: relative;
    padding: 7px;
    background: #6ABD83;
    border-radius: 100%;
    width: 40px;
    height: 40px;

    .icon-open {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
        opacity: 1;
        transform: rotate(0deg) scale(1);       
    }

    .icon-open.open {
        opacity: 0;
        transform: rotate(30deg) scale(0);     
    }
    
    .icon-close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
        opacity: 0;
        transform: rotate(-60deg);     
    }

    .icon-close.open {
        opacity: 1;
        transform: rotate(0deg);
    }
}
</style>