<template>
    <div class="farm-sync-icon">
        <div
            v-if="activeFarmHasXeroConnection"
            id="navbar-sync-new"
            :class="syncClass"
            class="flex-column full-width align-items-center flex"
        >
            <div class="full-width align-items-center flex justify-center">
                <el-dropdown
                    trigger="click"
                    placement="top"
                    @command="handleDropdownClick"
                >
                    <span class="el-dropdown-link full-width align-items-center xero-sync flex justify-center">
                        <img
                            :src="accountingSystemLogo"
                            width="40"
                            height="40"
                        >
                        <i
                            class="far xero-sync-icon"
                            :class="syncIcon"
                        />
                    </span>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="primary-nav-sync-menu"
                    >
                        <el-dropdown-item
                            v-show="!isRunning && isConnected"
                            command="sync"
                        >
                            <i class="fas fa-sync" />Sync
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-show="isRunning"
                            :disabled="true"
                            class="text-info"
                        >
                            <i class="fas fa-sync fa-spin text-info" />Syncing...
                        </el-dropdown-item>
                        <a :href="statusRoute">
                            <el-dropdown-item divided>
                                View {{ getLabelByAccountingSystem(activeFarmAccountingSystem) }} Connection
                            </el-dropdown-item>
                        </a>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import {globals, routes} from "Figured/Assets/Modules";
import SyncStatusMix from "XeroSync/Assets/Mixins/SyncStatus";
import {XERO_SYNC_STATUS} from "FarmCreation/Assets/consts";
import { getLabelByAccountingSystem } from "Assets/Modules/globals";

export default {
    mixins: [SyncStatusMix],

    data() {
        return {
            connectedToAccountingSystem: Figured.connectedToAccountingSystem,
            activeFarmHasXeroConnection: globals.get("activeFarmHasXeroConnection", false),
            activeFarmAccountingSystem:  globals.get("activeFarmAccountingSystem", null),
        }
    },

    computed: {
        isConnected() {
            return this.connectedToAccountingSystem;
        },

        isDisconnected() {
            return this.status === XERO_SYNC_STATUS.DISCONNECTED;
        },

        isFailed() {
            return this.status == XERO_SYNC_STATUS.ERROR;
        },

        isRunning() {
            return this.status == XERO_SYNC_STATUS.RUNNING;
        },

        connectedRoute() {
            return routes.get("sync.detailed_status");
        },

        disconnectedRoute() {
            return routes.get("settings.testconnection");
        },

        statusRoute() {
            return this.isDisconnected ? this.disconnectedRoute : this.connectedRoute;
        },

        accountingSystemLogo() {
            switch (this.activeFarmAccountingSystem) {
            case "intuit":
                return "/img/qb_logo.png";
            case "myob":
                return "/img/myob_logo.png";
            case "xero":
                return "/img/xero_logo.png";
            default: return "";
            }
        },

        syncIcon() {
            switch (this.status) {
            case XERO_SYNC_STATUS.WAITING:
            case XERO_SYNC_STATUS.INCOMPLETE:
                // No icon needed.
                return "fa-fw";
            case XERO_SYNC_STATUS.RUNNING:
                return "fa-sync fa-spin";
            case XERO_SYNC_STATUS.COMPLETE:
                return "fa-check-circle";
            case XERO_SYNC_STATUS.ERROR:
                return "fa-times-circle";
            case XERO_SYNC_STATUS.DISCONNECTED:
                return "fa-exclamation-circle";
            default: return "";
            }
        },

        syncClass() {
            if (this.isDisconnected) {
                return "disconnected";
            } else if (this.isFailed) {
                return "failure";
            } else {
                return "connected";
            }
        },
    },

    mounted() {
        if (this.isConnected === false) {
            this.setStatus(XERO_SYNC_STATUS.DISCONNECTED);
        } else if (this.isConnected === null) {
            // Waiting for a response from pusher.
            this.setStatus(XERO_SYNC_STATUS.WAITING);
        }
    },

    methods: {
        handleDropdownClick(command) {
            switch (command) {
            case "sync":
                SyncStatusMix.methods.startSync();
                this.setStatus(XERO_SYNC_STATUS.RUNNING);
                break;
            }
        },

        getLabelByAccountingSystem
    }
};
</script>

<style lang="scss" scoped>
.connected, .disconnected, .failure {
  .xero-sync-icon {
    margin-left: -1.2rem;
    margin-bottom: -1.6rem;
    z-index: 2;
    border-radius: 2rem;
    color: #FFFFFF;
    font-size: 1.2rem;
  }

  .fa-fw {
    height: 12px;
    width: 12px;
    opacity: 0;
  }

  .xero-sync {
    img {
      opacity: .8;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}

.connected .xero-sync-icon {
  background-color: #72CB9C;
}

.disconnected .xero-sync-icon {
  background-color: #ff4949;
}

.failure .xero-sync-icon {
  background-color: #9C5A3A;
}

#navbar-sync {
  .fa-sync {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.8);
  }
}

.el-dropdown-menu__item a {
  color: #606266;
}
</style>
