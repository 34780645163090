<template>
    <div class="user-profile-menu">
        <el-dropdown
            trigger="click"
            placement="top"
        >
            <span class="el-dropdown-link">
                <i class="fa-solid fa-circle-ellipsis" />
            </span>
            <el-dropdown-menu
                slot="dropdown"
                class="primary-nav-user-menu"
            >
                <a
                    v-for="(menuItem, key) in filteredMenuItems(menuItems)"
                    :id="getId(menuItem)"
                    :key="key"
                    :href="menuItem.url"
                    :target="menuItem.target"
                    @click="handleClick($event, menuItem.url)"
                >
                    <el-dropdown-item
                        :class="{active: isActive(menuItem)}"
                        :divided="hasDivider(menuItem)"
                    >
                        <i
                            v-if="menuItem.icon"
                            style="width: 20px"
                            :class="menuIconClass(menuItem)"
                        />
                        {{ transMenuItem(menuItem.name) }}
                    </el-dropdown-item>
                </a>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import NavigationMixin from "Navigation/Assets/Mixins/Navigation.js";

export default {

    mixins: [
        NavigationMixin
    ],

    data() {
        return {
            profileImageSrc: Figured.primarySiteNavUserProfilePicture,
            menuItems: Figured.primarySiteNavUserMenuItems
        }
    },

    methods: {
        async handleClick(event, url) {
            if (url !== "/auth/logout") {
                return;
            }

            event.preventDefault();

            const hasIntercom = (typeof window.Intercom === "function");

            Promise.resolve()
                .then(() => {
                    EventBus.$emit("figured-logout");

                    if (hasIntercom) {
                        return Intercom("shutdown");
                    }
                })
                .then(() => {
                    window.location.href = url;
                    return false;
                });
        },

        hasDivider(menuItem) {
            return Object.prototype.hasOwnProperty.call(menuItem, "divider")
                ? menuItem.divider
                : false;
        },

        getId: function (menuItem) {
            return _.get(menuItem, "id", false);
        },
    }
}
</script>

<style scoped>
    .el-dropdown {
        color: inherit;
        display: inherit;
        position: inherit;
        font-size: inherit;
        width: fit-content;
    }
</style>
<style lang="scss">
@import "src/Figured/Assets/Themes/default/_colours";
.user-profile-menu {
    .fa-circle-ellipsis {
        color: rgba(255,255,255,0.6);
        font-size: 40px;
    }

    .fa-circle-ellipsis:hover {
        color: #ffffff;
    }
}
</style>
