<template>
    <div class="profile-item user-help-menu">
        <el-dropdown
            ref="dropdown"
            v-model="dropdownVisible"
            trigger="click"
            placement="top"
        >
            <span class="el-dropdown-link">
                <span class="fa-solid fa-circle-question user-profile-icon-fa" />
            </span>
            <el-dropdown-menu
                slot="dropdown"
                class="primary-nav-support-menu"
                :append-to-body="false"
            >
                <template v-for="menuItem in filteredMenuItems(menuItems)">
                    <el-dropdown-item
                        :key="menuItem.url"
                        :class="{active: isActive(menuItem)}"
                    >
                        <a
                            target="_blank"
                            :href="menuItem.url"
                        >
                            <i
                                v-if="menuItem.icon"
                                :class="menuIconClass(menuItem)"
                            />
                            {{ transMenuItem(menuItem.name) }}
                        </a>
                    </el-dropdown-item>
                </template>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import NavigationMixin from "Navigation/Assets/Mixins/Navigation.js";

export default {
    mixins: [
        NavigationMixin
    ],

    data() {
        return {
            menuItems: Figured.primarySiteNavSupportMenuItems,
            dropdownVisible: false
        }
    },

    methods: {
        hideDropdown() {
            this.$refs.dropdown.hide();
        }
    },
}
</script>

<style lang="scss" scoped>
    .el-dropdown {
        color: inherit;
        display: inherit;
        position: inherit;
        font-size: inherit;
        width: fit-content;
    }

    .el-dropdown-menu {
        // Override Element text-align settings
        .el-dropdown-menu__item {
            text-align: left;
        }
    }
</style>
