<template>
    <el-menu
        v-if="shouldShowMenu"
        id="primary-site-nav-container"
        ref="mainNav"
        :class="'primary-site-nav-container'"
        :collapse="isCollapsed"
        :default-openeds="storedSubMenu"
        :default-active="activeItem"
        @open="handleSubMenuOpened"
        @close="handleSubMenuClosed"
    >
        <div class="flex-column flex h-full">
            <div :class="'flex align-items-center nav-top-section ' + renderTopClass ">
                <a :href="linkToFarms">
                    <img
                        v-if="collapsed"
                        :src="logoUrls.collapsed"
                        class="nav-logo-collapsed"
                    >
                    <img
                        v-else
                        :src="logoUrls.full"
                        class="nav-logo"
                    >
                </a>

                <div
                    v-if="isActiveContext"
                    class="main-nav-collapse"
                    :style="renderCollapseStyle"
                >
                    <a @click="toggleCollapse">
                        <i
                            v-if="!collapsed"
                            class="el-icon-d-arrow-left font-weight-bold"
                        />
                    </a>
                </div>

                <!-- Only appears on Mobile devices -->
                <div class="main-nav-hide-mobile">
                    <a @click="toggleHide">
                        <i class="fas fa-times font-weight-bold" />
                    </a>
                </div>
            </div>

            <div
                data-simplebar
                class="flex-column scrolling-nav flex flex-1 overflow-x-hidden"
            >
                <div class="main-menu-container menu-padding">
                    <div
                        v-if="!isActiveUser"
                        class="main-menu-container align-items-center flex"
                    >
                        <a
                            :href="linkToLogin"
                            class="btn btn-link back-to-login-link"
                        ><i class="el-icon-arrow-left" /> Login</a>
                    </div>

                    <template v-if="isActiveContext">
                        <div class="main-menu-container align-items-center flex">
                            <!-- Wrapping Element Menu Item with anchor as workaround for bug: https://github.com/ElemeFE/element/issues/13635 -->
                            <a
                                v-if="!collapsed || !hasMoreActiveEntities"
                                :href="activeContext.properties.url"
                                @click.prevent
                            >
                                <el-menu-item
                                    index="0-1"
                                    class="primary-nav-item"
                                    :style="collapsed ? 'padding-left: 3rem !important;' : ''"
                                    @click="goToUrl(activeContext)"
                                >
                                    <i :class="activeContext.properties.icon" />
                                    <span slot="title">{{ activeContext.label }}</span>
                                </el-menu-item>
                            </a>

                            <el-dropdown
                                v-if="hasMoreActiveEntities"
                                :class="{'entity-dropdown': !collapsed, 'entity-dropdown-collapsed': collapsed, 'el-menu-item': collapsed}"
                                @command="goToUrl"
                            >
                                <i
                                    v-if="collapsed"
                                    :class="activeContext.properties.icon"
                                />
                                <i
                                    v-else
                                    class="el-icon-arrow-down el-icon--right nav-icon"
                                />
                                <!-- Wrapping Element dropdown Item with anchor as workaround for bug: https://figured-dev.atlassian.net/browse/FIG-10152 -->
                                <el-dropdown-menu slot="dropdown">
                                    <a
                                        v-for="(activeEntity, index) in activeContextItems"
                                        :key="index"
                                        :href="activeEntity.properties.url"
                                    >
                                        <el-dropdown-item>
                                            {{ activeEntity.label }}
                                        </el-dropdown-item>
                                    </a>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>

                        <hr class="top-nav-divider">
                    </template>

                    <!-- Wrapping Element Menu Item with anchor as workaround for bug: https://github.com/ElemeFE/element/issues/13635 -->
                    <a
                        v-for="(activeEntity, index) in activeContextItems"
                        v-else
                        :key="index"
                        :href="activeEntity.properties.url"
                        @click.prevent
                    >
                        <el-menu-item
                            :index="index.toString()"
                            :class="activeEntity.properties.extraClasses.replace(/\bis\-active\b/, '')"
                            @click="goToUrl(activeEntity)"
                        >
                            <i :class="activeEntity.properties.icon" />
                            <span slot="title">{{ activeEntity.label }}</span>
                        </el-menu-item>
                    </a>

                    <div
                        v-for="(menuItem, index) in menuItems"
                        :key="index"
                        class="main-menu-container"
                    >
                        <!-- Show submenu if there are child elements. Replace submenu with a link if the nav is collapsed. -->
                        <el-submenu
                            v-if="menuItem.children"
                            :class="{'is-active-submenu': styleSubMenuParent && activeSubMenuParent === index.toString()}"
                            :index="index.toString()"
                            popper-class="figured-navbar-submenu"
                        >
                            <template slot="title">
                                <i :class="menuItem.properties.icon" />
                                <span slot="title">
                                    {{ menuItem.label }}
                                </span>
                                <span v-if="menuItemHasNotification(menuItem)">
                                    <el-badge
                                        class="item"
                                        is-dot
                                    />
                                </span>
                            </template>

                            <!-- Show submenu items as links -->
                            <!-- Wrapping Element Menu Item with anchor as workaround for bug: https://github.com/ElemeFE/element/issues/13635 -->
                            <a
                                v-for="(child, chIndex) in menuItem.children"
                                :href="child.properties.url"
                                @click.prevent
                            >
                                <el-menu-item
                                    :id="getIdString(child)"
                                    :key="chIndex"
                                    :index="child.index"
                                    :class="child.properties.extraClasses.replace(/\bis\-active\b/, '')"
                                    @click="goToUrl(child)"
                                >
                                    <span slot="title">
                                        {{ child.label }}
                                    </span>

                                    <span class="sub-report">
                                        <span
                                            v-if="menuItemHasNotification(child)"
                                            class="menu-item-notification"
                                        >
                                            {{ collapsed ? '' : child.properties.notification }}
                                        </span>
                                    </span>
                                </el-menu-item>
                            </a>
                        </el-submenu>

                        <!-- No submenu is present, display as normal link -->
                        <!-- Wrapping Element Menu Item with anchor as workaround for bug: https://github.com/ElemeFE/element/issues/13635 -->
                        <a
                            v-else
                            :href="menuItem.properties.url"
                            @click.prevent
                        >
                            <el-menu-item
                                :key="index"
                                :index="index.toString()"
                                :class="menuItem.properties.extraClasses.replace(/\bis\-active\b/, '')"
                                @click="goToUrl(menuItem)"
                            >
                                <i :class="menuItem.properties.icon">
                                    <span
                                        v-if="menuItemHasNotification(menuItem)"
                                        class="menu-item-notification"
                                    >
                                        {{ collapsed ? '' : menuItem.properties.notification }}
                                    </span>
                                </i>
                                <span slot="title">
                                    {{ menuItem.label }}
                                </span>
                            </el-menu-item>
                        </a>
                    </div>
                </div>
            </div>

            <hr class="bottom-nav-divider">

            <UserProfileGroup
                :is-authed="isAuthed"
                :is-collapsed="collapsed"
                :is-profile-slide-open="profileSlideOpen"
            />
        </div>
    </el-menu>
</template>

<script>
import { routes, lang } from "Figured/Assets/Modules";
import { SideNavEvent } from "../side-nav-event-bus";
import UserProfileGroup from "./UserProfileGroup";
import "simplebar";
import "simplebar/dist/simplebar.css";

// Prefix local storage keys with app name to avoid cross domain name collisions.
// TODO this should be in a local storage wrapper class instead.
const STORAGE_PREFIX = "figured.";

export default {
    components: {
        UserProfileGroup,
    },

    props: {
        isAuthed: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            activeItem: null,
            collapsedSubmenus: false,
            collapsed: false,
            isVisible: true,
            activeSubMenuParent: null,
            styleSubMenuParent: false,
            profileSlideOpen: false,

            farmMenuItems: Figured.farmMenuItems,
            orgMenuItems: Figured.orgMenuItems,
            activeContextItems: Figured.activeContextItems,
            logoUrls: Figured.logoUrls,
        }
    },

    computed: {
        shouldShowMenu() {
            return (this.hasLoaded || !this.isActiveContext) && this.isVisible;
        },

        renderTopClass() {
            return this.collapsed ? "justify-center" : "justify-space-between";
        },

        renderCollapseStyle() {
            return this.collapsed ? "margin-top: 2rem;" : "";
        },

        linkToFarms() {
            return routes.get("farms.index");
        },

        linkToLogin() {
            return routes.get("login")
        },

        menuItems() {
            if (this.isActiveOrganisation) {
                return this.orgMenuItems;
            }

            return this.farmMenuItems;
        },

        hasLoaded() {
            return !_.isNull(this.collapsed);
        },

        isActiveUser() {
            return globalVars.activeUserId;
        },

        isActiveFarm() {
            return globalVars.activeFarmId;
        },

        isActiveOrganisation() {
            if (globalVars.activeOrgId) {
                // Check if Organisation is the active menu item. (For Benchmarking)
                const organisation = this.getOrganisationContext();

                if (organisation) {
                    let orgClasses = organisation.properties.extraClasses;

                    return orgClasses.includes("is-active");
                }
            }

            return false;
        },

        isActiveContext() {
            return (this.isActiveFarm || this.isActiveOrganisation);
        },

        isCollapsed() {
            if (!this.isActiveContext) {
                return false;
            }

            return this.collapsed;
        },

        activeContext() {
            if (this.isActiveContext) {
                if (this.isActiveFarm) {
                    return this.getFarmContext();
                }

                return this.getOrganisationContext();
            }

            return null;
        },

        hasMoreActiveEntities() {
            return this.activeContextItems.length > 1;
        },

        /**
             * Get the open submenu from storage (if any)
             */
        storedSubMenu() {
            return JSON.parse(localStorage.getItem(STORAGE_PREFIX + "sub-menus-open"))
        },

    },

    watch: {
        /**
             * Watch for the navbar to expand/collapse, and on collapse set the parent of the submenu that contains the
             * active menu item to `.is-active`.
             */
        collapsed: function(val) {
            if (val === true) {
                this.styleSubMenuParent = true;
                document.getElementById("figured-navbar").style.minWidth = "inherit";
            } else {
                this.profileSlideOpen = false;
            }
        }
    },

    mounted() {
        /**
             * Assign the index of the submenu parent that contains the active menu item to data
             */
        this.menuItems.forEach((item, index) => {
            if (item.children) {
                item.children.forEach((child, chIndex) => {
                    if (child.properties.extraClasses.includes("is-active")) {
                        this.activeSubMenuParent = index.toString();

                        // If the parent submenu is not open, assign the active class
                        if (!this.storedSubMenu) {
                            this.styleSubMenuParent = true;
                        }
                    }
                });
            }
        });
    },

    /**
         * On creation of this component, we firstly:
         *  - Check if we have an active farm, to determine whether collapse/expand ability should be available or not.
         *    - If active farm is set, we then fetch the collapsed state stored within local storage of the browser
         *      to add persistence of users collapsed/expanded preference.
         *
         *      Note: This needs to be in the created instead of mounted lifecycle hook, because if in mounted, when
         *      the nav is collapsed and then the page is refreshed, the nav will show UNCOLLAPSED for a second before
         *      showing the collapsed state.
         */
    created() {
        this.assignIndexes(this.farmMenuItems);
        this.assignIndexes(this.orgMenuItems);

        this.activeItem = this.getActiveItem();

        if (this.isActiveContext) {
            SideNavEvent.$on("side-nav-collapse", () => {
                this.setCollapsedFlag();
            });

            this.setCollapsedFlag();
        }

        SideNavEvent.$on("mobile-nav-show", () => {
            this.collapsed = false;
            this.isVisible = true;
            localStorage.setItem(STORAGE_PREFIX + "side-nav-collapsed", "false");
        });
    },

    methods: {
        assignIndexes(menuItems) {
            menuItems.forEach((item, index) => {
                item.index = index.toString();

                item.children && item.children.forEach((child, chIndex) => {
                    child.index = this.getMenuItemIndex(index, chIndex);
                });
            });
        },

        getActiveItem(path) {
            path = path || window.location.pathname;

            let activeItem = null;

            for (let i = 0; i < this.menuItems.length; i++) {
                let item = this.menuItems[i];

                if (item.properties.url.indexOf(path) > -1) {
                    activeItem = item.index;
                }

                if (item.children) {
                    for (let j = 0; j < item.children.length; j++) {
                        let child = item.children[j];
                        if (child.properties.url.indexOf(path) > -1) {
                            return child.index;
                        }
                    }
                }

                /*
                     * Only return parent if no child matches
                     */
                if (activeItem) return activeItem;
            }
        },

        /**
             * Closes all navigation submenus when another is opened, so that only one submenu is ever open at one time.
             * Element unsurprisingly does not provide a method to close all submenus, only a method to close a
             * specific submenu using an index. So we iterate through every menu item, and attempt to close it.
             *
             * Also includes functionality to add or remove the active class from a submenu parent item depending on
             * whether or not it is the submenu that the user has just opened
             *
             * @var int index
             */
        handleSubMenuOpened(currentIndex) {
            // If we didn't click on the currently active submenu, add the active class to the parent of
            // the active submenu. This is required as for some reason calling the `.close()` function
            // above doesn't trigger Element's built-in `@close` method, which in theory should automatically
            // call the below function, but it doesn't...
            this.styleSubMenuParent = true;

            this.menuItems.forEach ((item, index) => {
                index = index.toString(); // The indexes should be strings (for some reason ;-;)

                // We don't want to close the submenu we just opened, so we skip that
                if (!(currentIndex === index)) {
                    // Attempt to close the menu item at the current index
                    this.$refs.mainNav.close(index);
                }
            });

            // If we are opening the submenu that contains the active menu item, we can remove the `.is-active`
            // class from the parent
            if (currentIndex === this.activeSubMenuParent) {
                this.styleSubMenuParent = false;
            }

            // Save the open state of the submenu
            this.addSubMenuOpenToStorage(currentIndex);
        },

        /**
             * Called when a submenu is closed
             */
        handleSubMenuClosed() {
            this.styleSubMenuParent = true;

            // Remove the stored open state
            this.removeSubMenuFromStorage();
        },

        /**
             * Add the submenu index to the localStorage
             *
             * @var int index
             */
        addSubMenuOpenToStorage(index) {
            localStorage.setItem(STORAGE_PREFIX + "sub-menus-open", JSON.stringify([index]));
        },

        /**
             * Add the submenu index from the localStorage
             */
        removeSubMenuFromStorage() {
            localStorage.removeItem(STORAGE_PREFIX + "sub-menus-open");
        },

        toggleCollapse() {
            this.collapsed = !this.collapsed;
            // Store users preference for Nav's collapsed/expanded display
            localStorage.setItem(STORAGE_PREFIX + "side-nav-collapsed", this.collapsed);

            // Send notification for Side Nav collapse change.
            SideNavEvent.$emit("side-nav-collapse");

        },

        toggleHide() {
            // This is used on Mobile only as component only renders on smaller screens.
            this.isVisible = false;
            $(".figured-navbar").hide();
        },

        goToUrl(menuItem) {
            this.activeItem = this.menuItems.indexOf(menuItem).toString();

            const path      = menuItem.properties.url;
            const isInertia = menuItem.inertia;

            $(".el-submenu").removeClass("is-active");

            this.activeItem = this.getActiveItem(path);

            if (isInertia && this.$inertia) {
                this.$inertia.get(path);

                return false;
            }

            window.location = path;
        },

        getFarmContext() {
            return _.first(this.activeContextItems);
        },

        /**
             * Create an id string that matches the menu item title, or use a specific ID if required.
             *
             * @var object menuItem
             * @return string
             */
        getIdString(menuItem) {
            return _.isEmpty(menuItem.properties.element_id)
                ? _.kebabCase(menuItem.label)
                : menuItem.properties["element_id"];
        },

        getMenuItemIndex(index, childIndex) {
            return index.toString() + "-" + childIndex.toString();
        },

        getOrganisationContext() {
            const orgText = lang.get("terminology.org_heading");

            return _.find(this.activeContextItems, contextItem => {
                return contextItem.label === orgText;
            });
        },

        getCollapsedFlag() {
            return (localStorage.getItem(STORAGE_PREFIX + "side-nav-collapsed") === "true");
        },

        setCollapsedFlag() {
            this.collapsed = this.getCollapsedFlag();
        },

        menuItemHasNotification(menuItem) {
            return !_.isNull(menuItem.properties.notification) && menuItem.properties.notification > 0;
        },
    }
};
</script>

<style lang="scss">
    /* Allows collapse state to work as intended once component has loaded. */
    .figured-navbar {
        min-width: inherit !important;
        width: inherit !important;
    }

    /* Override element submenu positioning so the popper doesn't cover the collapsed menu border. */
    .figured-navbar-submenu {
        left: 7rem !important;
    }

    /* Overwrite the default simplebar scrollbar color */
    .simplebar-scrollbar:before {
        background: white !important;
    }

    /* Explicit position declaration required for simplebar */
    .scrolling-nav {
        position: relative;
    }
</style>
