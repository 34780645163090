<template>
    <div
        v-if="isAuthed"
        class="align-items-center user-profile-group flex"
        :class="{'user-profile-group-collapsed': isCollapsed, 'user-profile-group-nosync': !isActiveFarm}"
    >
        <UserMenu />
        <XeroSync v-if="isActiveFarm" />
        <Intercom
            v-if="isStagingOrProduction"
            :is-collapsed="isCollapsed"
        />
    </div>
</template>

<script>
import XeroSync from "./XeroSync";
import UserMenu from "./UserMenu";
import Intercom from "./Intercom";

export default {
    components: {
        UserMenu,
        XeroSync,
        Intercom
    },

    props: {
        isAuthed: {
            type: Boolean,
            required: true
        },
        isCollapsed: {
            type: Boolean,
            default: false,
            required: true
        }
    },

    data() {
        return {}
    },

    computed: {
        isActiveFarm() {
            // Ensure that this returns a bool
            return window.globalVars.activeFarmId !== false;
        },
        isStagingOrProduction() {
            return window.globalVars.env === "production" || window.globalVars.env.startsWith("staging");
        }
    },
}
</script>
